import { useState, useEffect, useCallback } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { SUPPORTED_CHAINS } from "../constants";

export const chains = {
  80001: {
    name: "Mumbai Polygon",
    etherScanPrefix: "mumbai.",
  },
};

export const injected = new InjectedConnector({
  // See https://chainid.network/
  supportedChainIds: SUPPORTED_CHAINS,
});

export const isValidChainId = (chainId) => (chainId ? SUPPORTED_CHAINS.includes(chainId) : undefined);

export function useEagerConnect() {
  const [tried, setTried] = useState(false);
  const { activate } = useWallet();

  useEffect(() => {
    const setTriedFalse = () => setTried(false);
    if (!window.ethereum) {
      return;
    }

    window.ethereum.on("chainChanged", setTriedFalse);
    window.ethereum.on("accountChanged", setTriedFalse);

    return () => {
      window.ethereum.removeListener("chainChanged", setTriedFalse);
      window.ethereum.removeListener("accountChanged", setTriedFalse);
    };
  }, [setTried]);

  useEffect(() => {
    if (!activate || tried) {
      return;
    }

    setTried(true);

    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate();
      }
    });
  }, [activate, tried]);
}

export const useWallet = () => {


  const networks = {

    bsc: {
      chainId: `0x${Number(56).toString(16)}`,
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: [
        "https://bsc-dataseed1.binance.org",
        "https://bsc-dataseed2.binance.org",
        "https://bsc-dataseed3.binance.org",
        "https://bsc-dataseed4.binance.org",
        "https://bsc-dataseed1.defibit.io",
        "https://bsc-dataseed2.defibit.io",
        "https://bsc-dataseed3.defibit.io",
        "https://bsc-dataseed4.defibit.io",
        "https://bsc-dataseed1.ninicoin.io",
        "https://bsc-dataseed2.ninicoin.io",
        "https://bsc-dataseed3.ninicoin.io",
        "https://bsc-dataseed4.ninicoin.io",
        "wss://bsc-ws-node.nariox.org"
      ],
      blockExplorerUrls: ["https://bscscan.com"]
    },
    mumbai: {
      chainId: `0x13881`,
      chainName: "Mumbai Polygon",
      nativeCurrency: {
        name: "Matic Polygon",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: [
        "https://polygon-mumbai-bor.publicnode.com",
        "https://polygon-testnet.public.blastapi.io	"
      ],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
    }
  };

  const changeNetwork = async ({ networkName }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...networks[networkName]
          }
        ]
      });
    } catch (err) {
      console.log(err.message);
    }
  };


  const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName });
  };


  const web3React = useWeb3React();

  const activate = useCallback(() => {
    web3React.activate(injected, (error) => {
      if (error instanceof UnsupportedChainIdError) {
        handleNetworkSwitch("mumbai");
        web3React.activate(web3React.connector); // Can't use setError because the connector isn't set

      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3React]);

  return {
    activate,
    isActive: web3React.active,
    deactivate: web3React.deactivate,
    chainId: web3React.chainId,
    account: web3React.account,
    isMetaMask: window.ethereum?.isMetaMask,
    library: web3React.library,
  };
};
