import React from "react";
import styled from "@emotion/styled";
import { ConnectButton } from "../components/ConnectButton";

import { useMediaQuery } from "react-responsive"



const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 `;

export const Header = () => {

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
  return (
    < StyledHeader >
      <div style={isMobile ? { margin: "18px  0px 0px 15px" } : { margin: "50px 0px 0px 60px" }}>
        <img src="/flippy-logo.png" width={isMobile ? "145px" : "250px"} height={isMobile ? "50px" : "113PX"} />
      </div>
      <div style={{ marginTop: isMobile ? "22px" : "50px", marginRight: isMobile ? "25px" : "85px" }}>
        <ConnectButton />
      </div>
    </StyledHeader >)
};
