import React from "react";

const toSignificant = (value, signif) => {
  const [base, decimals] = value.split(".");
  return [base, (decimals && decimals.substring(0, signif - base.length)) || "0"].join(".");
};

export const MATIC = ({ children, signif = 6 }) => {
  const converted = toSignificant(children || "0", signif);

  return <span className="flex flex-row">&nbsp;&nbsp;{converted}&nbsp;<img src='images/matic.png' width="24px" alt='matic' />
  </span>;
};
