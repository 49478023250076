import React, { useState, useEffect } from "react";
import styled from '@emotion/styled';
import { formatEther } from "@ethersproject/units";
import { faTrophy, faSadTear, faHourglass1 } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button";
import { ConnectButton } from "./ConnectButton";
import { useWallet } from "../hooks/useWallet";
import { useAppContext } from "../AppContext";
import { useCoinFlipContract } from "../hooks/useContract";
import { useFunction } from "../hooks/useFunction";
import { BsMoonStarsFill } from 'react-icons/bs';
import { IoMdSunny } from 'react-icons/io';
import { motion, useAnimation } from "framer-motion";
import { useMediaQuery } from 'react-responsive'
import { ImEarth } from 'react-icons/im'
import { AiFillFire } from 'react-icons/ai'
import ReactPlayer from 'react-player'
import { ethers } from "ethers";
import { COINFLIP_ADDRESS } from "../constants";
import { abi } from "../abis/coinFlip";

export const Game = () => {


  //Limit Update Profile every 45 seconds
  const [canRun, setCanRun] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCanRun(true);
    }, 45000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const StyledCoinWrapper = styled.div`
width: 100%;
height: 100%;
`;


  // 2X - 2 Choices
  const LeftDiv = styled.div`
background-color: #f9c80e;
width: 50%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px 0 0 5px;

&:hover {
  background-color: #000;
}
`;

  const RightDiv = styled.div`
background-color: #f9c80e;
width: 50%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
border-radius: 0 5px 5px 0;

justify-content: center;
&:hover {
  background-color: #000;
}
`;


  // 4X - 4 Choices

  const LeftDivFour = styled.div`
background-color: #f9c80e;
width: 25%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px 0 0 5px;

&:hover {
  background-color: #000;
}
`;

  const LeftAfterDiv = styled.div`
background-color: #f9c80e;
width: 25%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
 
justify-content: center;
&:hover {
  background-color: #000;
}
`;

  const RightAfterDiv = styled.div`
background-color: #f9c80e;
width: 25%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
 
justify-content: center;
&:hover {
  background-color: #000;
}
`;

  const RightDivFour = styled.div`
background-color: #f9c80e;
width: 25%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
border-radius: 0 5px 5px 0;

justify-content: center;
&:hover {
  background-color: #000;
}
`;


  const Line = styled.div`
  width: 2px;
  height: 100%;
  background-color: #000;
`;

  const GradientText = styled.span`
 background: linear-gradient(to right, #ffea00, #fbc000);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
`;

  const { isActive, account } = useWallet();
  const { balance, profit, getContractBalance, syncAll, addNotification, maxBetTwo, maxBetFour, wonCount, loseCount, theme: themeSelected } = useAppContext();
  const contract = useCoinFlipContract();
  const [betAmount, setBet] = useState(0.01);
  const [gameMode, setGameMode] = useState(0);
  const [messageField, setMessageField] = useState("");

  const LeftGame = styled.div`
background-color: ${gameMode === 0 ? "#f9c80e" : "#E1750A"};
width: 50%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px 0 0 5px;
${gameMode === 0 ?
      null
      : `&:hover {
        background-color: red;
      }`}
`;

  const RightGame = styled.div`
background-color: ${gameMode === 1 ? "#f9c80e" : "#E1750A"};
width: 50%;
height: 100%;
transition: all 0.3s ease-out;
display: flex;
align-items: center;
border-radius: 0 5px 5px 0;
justify-content: center;

${gameMode === 1 ?
      null
      : `&:hover {
    background-color:  red;
  }`}

`;

  const div1Controls = useAnimation()
  const div2Controls = useAnimation()


  const div1Variants = {
    hidden: { x: -100, opacity: 0, display: "none" },
    visible: { x: 0, opacity: 1, display: "block" }
  }

  const div2Variants = {
    hidden: { x: 100, opacity: 0, display: "none" },
    visible: { x: 0, opacity: 1, display: "block" }
  }

  useEffect(() => {
    if (gameMode === 0) {
      div1Controls.start("visible")
      div2Controls.start("hidden")
    } else {
      div1Controls.start("hidden")
      div2Controls.start("visible")
    }
  }, [gameMode, div1Controls, div2Controls])

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })


  const provider = new ethers.WebSocketProvider(process.env.REACT_APP_MUMBAI_WSS)

  const contractBets = new ethers.Contract(COINFLIP_ADDRESS, abi, provider);
  contractBets.on('BetResult', (address, win, value) => {
    console.log(address)
    if (address === account) {
      console.log(win)
      syncAll()
      addNotification({
        title: win ? `You won ${formatEther(value)} MATIC` : `You've lost ${formatEther(value)} MATIC`,
        icon: win ? faTrophy : faSadTear,
        isSuccess: win,
        isError: !win,
      });
    } else {
      getContractBalance();
    }
  },
    [addNotification]
  );

  const handleGameMode = (gm) => {
    if (gm === 0) {
      return setGameMode(0)
    }
    return setGameMode(1)
  };

  const handleBetTwo = (bet) => {
    if (betAmount === 0 || betAmount < 0.01 || betAmount > parseFloat(balance)) {
      return null;
    }
    if (canRun) {
      setCanRun(false);
      if (bet === 0) {
        return doFlipMoonTwo()
      }
      return doFlipSunTwo()
    } else {
      addNotification({
        title: `Please wait 45 seconds!`,
        icon: faHourglass1,
        isError: true,
      });
    }
  };

  const handleBetFour = (bet) => {
    if (betAmount === 0 || betAmount < 0.01 || betAmount > parseFloat(balance)) {
      return null;
    }

    if (canRun) {
      setCanRun(false);
      switch (bet) {
        case 0:
          return doFlipMoonFour()
        case 1:
          return doFlipSunFour()
        case 2:
          return doFlipEarthnFour()
        case 3:
          return doFlipFireFour()

        default:
          null
      }
    } else {
      addNotification({
        title: `Please wait 45 seconds!`,
        icon: faHourglass1,
        isError: true,
      });
    }
  };


  const doFlipMoonTwo = useFunction("betTwo", betAmount, [0]);
  const doFlipSunTwo = useFunction("betTwo", betAmount, [1]);

  // 4 Bets

  const doFlipMoonFour = useFunction("betFour", betAmount, [0]);
  const doFlipSunFour = useFunction("betFour", betAmount, [1]);
  const doFlipEarthnFour = useFunction("betFour", betAmount, [2]);
  const doFlipFireFour = useFunction("betFour", betAmount, [3]);

  const handleCollect = () => {
    if (canRun) {
      setCanRun(false);
      return collectFunds();
    } else {
      addNotification({
        title: `Please wait 45!`,
        icon: faHourglass1,
        isError: true,
      });
    }
  }

  const collectFunds = useFunction("withdrawPlayerBalance");

  if (!isActive || !account) {
    return (

      <>
        <div className="text-center text-[30px] mb-4 wave-text">Welcome to Flippy</div>
        <div className='p-hero__video-embed'>
          <ReactPlayer
            className='react-player fixed-bottom ml-15 rounded-[40px] p-hero__video-embed '
            url='/videos/welcome.mp4'
            width='100%'
            height='100%'
            loop
            controls={true} />
        </div><br />
        <ConnectButton block className="text-[25px]">Connect your wallet to start</ConnectButton>
      </>
    )

  }

  if (!contract) {
    return <p>Could not connect to the contract</p>;
  }


  const handleBNBAmount = (e) => {
    const newValue = parseFloat(e.target.value);

    if (newValue === 0 && !Number.isNaN(newValue)) {
      setBet(0);
      return setMessageField("⚠️ Minimum bet is 0.01 MATIC");
    }
    if (newValue < 0.01 && !Number.isNaN(newValue)) {
      setBet(0);
      return setMessageField("⚠️ Minimum bet is 0.01 MATIC");
    }
    if (newValue > parseFloat(balance).toFixed(4) && !Number.isNaN(newValue)) {
      setBet(0);
      return setMessageField("⚠️ You don't have enough MATIC");
    }

    if ((!Number.isNaN(newValue) && newValue >= 0.01)) {
      setBet(e.target.value);
    }
    setMessageField("");

  };


  return (
    <div>
      <h2 style={{ textAlign: 'center' }} className="text-[17px] sm:text-[28px]"><span className=" waving-hand text-[20px] sm:text-[32px]" >👋</span> Welcome, <GradientText>{account.substring(0, 5) + "..." + account.substring(account.length - 5)}</GradientText></h2 >
      <br />
      <p className="text-center">Ready to flip to win? Our flip game is the ultimate test of luck. Flip it to win it!</p>
      <p className="text-left mt-4 text-[21px] sm:text-[28px]">We offer Two Game Modes:</p>
      <div className="text-left mt-2 mb-2">
        <span className="text-red-600">2X</span> -There is a <span className="text-red-600">50%</span> probability that your MATIC will be doubled <br />
      </div>
      <div className="text-left mt-2 mb-2">
        <span className="text-red-600">4X</span> -There is a <span className="text-red-600">25%</span> chance that your MATIC will be quadrupled <br />
      </div>


      <p className="text-center mt-4 sm:text-[25px] text-[18px] text-amber-300 moving-zooming-out">🔥GAME MODE🔥</p>



      <StyledCoinWrapper className="mt-2 flex flex-row items-center">
        <LeftGame onClick={() => handleGameMode(0)} className="flex flex-row">
          <span className={gameMode === 0 ? "moving-zooming-out-fast sm:text-[25px] text-[18px] p-12 text-center" : "text-center sm:text-[25px] text-[18px] p-12"}>Win 2X</span><span></span>
        </LeftGame>
        <RightGame onClick={() => handleGameMode(1)}>
          <span className={gameMode === 1 ? "moving-zooming-out-fast sm:text-[25px] text-[18px] p-12 text-center" : "text-center sm:text-[25px] text-[18px] p-12"}>Win 4X</span>
        </RightGame>
      </StyledCoinWrapper>

      <div className={themeSelected !== "dark" ? 'bg-zinc-200/60 rounded-lg p-4 mt-4' : 'bg-neutral-900/30 rounded-lg p-4 mt-4'}>
        <div className="relative flex items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 sm:text-[25px] text-[20px] text-black-400">Statistics</span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>

        <div className="flex items-center justify-between flex-row">
          <div className="flex flex-row w-full content-center items-center">
            <span className="flex content-center items-center mr-2">
              <img src='images/matic.png' width="24px" alt='matic' />
            </span>
            <span className="flex flex-col sm:flex-row w-1/2 sm:w-full sm:text-[17px] text-[13px]">
              Account Balance:
            </span>
          </div>
          <div className="flex flex-col items-end place-content-end sm:flex-row  w-1/2 sm:w-full font-bold sm:text-[32px] text-[18px]">
            <span className="sm:text-[24px] text-[18px] mr-0 sm:mr-1">
              {parseFloat(balance).toFixed(4)} MATIC
            </span>

          </div>
        </div>
        <div className="flex items-center justify-between flex-row">
          <div className="flex flex-row w-full content-center items-center">
            <span className="flex content-center items-center mr-1">
              🏆
            </span>
            <span className="flex flex-col sm:flex-row w-1/2 sm:w-full sm:text-[17px] text-[13px]">
              Bets Won:
            </span>
          </div>
          <div className="flex flex-col items-end place-content-end sm:flex-row  w-1/2 sm:w-full font-bold sm:text-[32px] text-[18px]">
            <span className="sm:text-[24px] text-[18px] mr-0 sm:mr-1">
              <GradientText>{wonCount}</GradientText>
            </span>

          </div>
        </div>
        <div className="flex items-center justify-between flex-row">
          <div className="flex flex-row w-full content-center items-center">
            <span className="flex content-center items-center mr-1">
              😢
            </span>
            <span className="flex flex-col sm:flex-row w-1/2 sm:w-full sm:text-[17px] text-[13px]">
              Bets Lost:
            </span>
          </div>
          <div className="flex flex-col items-end place-content-end sm:flex-row  w-1/2 sm:w-full font-bold sm:text-[32px] text-[18px]">
            <span className="sm:text-[24px] text-[18px] mr-0 sm:mr-1">
              <GradientText>{loseCount}</GradientText>
            </span>

          </div>
        </div>
        <div className="flex items-center justify-between flex-row">
          <div className="flex flex-row w-full content-center items-center">
            <span className="flex content-center items-center mr-1">
              🤑
            </span>
            <span className="flex flex-col sm:flex-row w-1/2 sm:w-full sm:text-[17px] text-[13px]">
              Your Profit:
            </span>
          </div>
          <div className="flex flex-col items-end place-content-end sm:flex-row  w-1/2 sm:w-full font-bold sm:text-[32px] text-[18px]">
            <span className="sm:text-[24px] text-[18px] mr-0 sm:mr-1">
              {parseFloat(profit).toFixed(4)} MATIC
            </span>
          </div>
        </div>

        {profit && profit !== "0.0" &&

          (
            <div className="flex items-center justify-between flex-row">
              <div className="flex flex-row w-full content-center items-center">
                <span className="flex content-center items-center mr-1">
                  🫴
                </span>
                <span className="flex flex-col sm:flex-row w-1/2 sm:w-full sm:text-[17px] text-[13px]">
                  Collect Profit:
                </span>
              </div>
              <div className="flex flex-col items-end place-content-end sm:flex-row  w-1/2 sm:w-full font-bold sm:text-[32px] text-[18px]">
                <span className="sm:text-[24px] text-[18px] mr-0 sm:mr-1">
                  <Button onClick={handleCollect}>Collect</Button>
                </span>
              </div>
            </div>
          )
        }
        <div className="relative flex py-5 items-center mt-4">
          <span className="sm:text-[20px] text-[15px] mr-1 sm:mr-4 font-bold">MIN: <GradientText>0.01 MATIC</GradientText></span>
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="sm:text-[20px] text-[15px]   ml-1 sm:ml-4  font-bold">MAX: <GradientText>{gameMode === 0 ? parseFloat(maxBetTwo).toFixed(3) : parseFloat(maxBetFour).toFixed(3)} MATIC</GradientText></span>

        </div>

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className='max-w-full'>

          <div style={{
            position: "relative",
            display: "flex",
            width: "100%",
            marginBottom: '5px',
          }}>
            <input type="number" onChange={handleBNBAmount} defaultValue="0.01" style={{
              margin: "0",
              boxSizing: "border-box",
              padding: "8px 12px",
              width: "100%",
              paddingLeft: "75px",
              background: themeSelected === "dark" ? "#000" : "#fff",
              textShadow: "2px 2px 1px rgba(0, 0, 0, 0.4)",

              border: `2px solid #f5fbe7`,
              borderRadius: `8px`,
              fontSize: "1.05rem",
              transition: "border 150ms ease-in-out",
              letterSpacing: "0.09em",
              fontWeight: "700",

              focused: {
                border: `2px solid #fcc600`,
              },
            }} />
            <div style={{
              position: 'absolute',
              left: '10px',
              top: '10px',
              color: "#F3BA2F",
              textShadow: "2px 2px 1px rgba(0, 0, 0, 0.4)",
            }} >MATIC</div>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: messageField !== "" ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            <span
              style={{
                fontWeight: "bold",
                color: "red"
              }}
            >
              {messageField}
            </span>
          </motion.div>

          <div className="text-center mt-4">Choose your card 🃏</div>


          <motion.div animate={div1Controls} variants={div1Variants}>
            {isMobile
              ?
              <StyledCoinWrapper className="mt-2 flex flex-row items-center">
                <LeftDiv onClick={() => handleBetTwo(0)}>
                  <BsMoonStarsFill color="white" size={33} className="m-11" />
                </LeftDiv>
                <Line />

                <RightDiv onClick={() => handleBetTwo(1)}>
                  <IoMdSunny color="white" size={33} className="m-11" />
                </RightDiv>
              </StyledCoinWrapper>
              :
              <StyledCoinWrapper className="mt-2 flex flex-row items-center">
                <LeftDiv onClick={() => handleBetTwo(0)}>
                  <BsMoonStarsFill color="white" size={50} className="m-12" />
                </LeftDiv>
                <Line />

                <RightDiv onClick={() => handleBetTwo(1)}>
                  <IoMdSunny color="white" size={50} className="m-12" />
                </RightDiv>
              </StyledCoinWrapper>
            }

          </motion.div>
          <motion.div animate={div2Controls} initial={{ x: 100, opacity: 0, display: "none" }} variants={div2Variants} >
            {isMobile
              ?
              <StyledCoinWrapper className="mt-2 flex flex-row items-center w-1/2	" >
                <LeftDivFour onClick={() => handleBetFour(0)}>
                  <IoMdSunny color="white" size={20} className="m-5" />
                </LeftDivFour>
                <Line />

                <LeftAfterDiv onClick={() => handleBetFour(1)}>
                  <BsMoonStarsFill color="white" size={20} className="m-5" />
                </LeftAfterDiv>
                <Line />

                <RightAfterDiv onClick={() => handleBetFour(2)}>
                  <ImEarth color="white" size={20} className="m-5" />
                </RightAfterDiv>
                <Line />

                <RightDivFour onClick={() => handleBetFour(3)}>
                  <AiFillFire color="white" size={20} className="m-5" />
                </RightDivFour>
              </StyledCoinWrapper>
              :
              <StyledCoinWrapper className="mt-2 flex flex-row items-center w-1/2	" >
                <LeftDivFour onClick={() => handleBetFour(0)}>
                  <IoMdSunny color="white" size={25} className="m-10" />
                </LeftDivFour>
                <Line />

                <LeftAfterDiv onClick={() => handleBetFour(1)}>
                  <BsMoonStarsFill color="white" size={25} className="m-10" />
                </LeftAfterDiv>
                <Line />

                <RightAfterDiv onClick={() => handleBetFour(2)}>
                  <ImEarth color="white" size={25} className="m-10" />
                </RightAfterDiv>
                <Line />

                <RightDivFour onClick={() => handleBetFour(3)}>
                  <AiFillFire color="white" size={25} className="m-10" />
                </RightDivFour>
              </StyledCoinWrapper>
            }

          </motion.div>

          <motion.div animate={div1Controls}>

          </motion.div>
        </div>
      </div>
      <p
        className="mt-4"
        style={{
          fontSize: "0.8em",
          opacity: 0.91,
        }}
      >
        Note: As this game is using  <GradientText><a href="https://chain.link/vrf" rel="noopener noreferrer" target="_blank"> Chainlink Verifiable Randomness Function (VRF)</a> </GradientText> to generate a result, it may take up to 30 seconds to get a result. <br /><GradientText>That being said, take your time and enjoy the game!</GradientText>
        {/* <br /> <br /> */}
        {/* Contract BSC: <GradientText><a href="https://bscscan.com/address/0x226C6764c4dDa49f5F7c0432910437cA07F7f670" rel="noopener noreferrer" target="_blank">0x226C6764c4dDa49f5F7c0432910437cA07F7f670</a></GradientText> */}
      </p>
    </div >
  );
};

