import React, { useEffect, useState } from "react";

import { Button } from "./Button";
import { Card } from "./Card";
import { MATIC } from "./MATIC";
import { useAppContext } from "../AppContext";
import { useCoinFlipContract } from "../hooks/useContract";
import { useFunction } from "../hooks/useFunction";
import { useWallet } from "../hooks/useWallet";

const useOwnerAddress = () => {
  const contract = useCoinFlipContract();
  const [ownerAddress, setOwnerAddress] = useState(null);

  useEffect(() => {
    if (!contract) {
      return;
    }
    contract.functions.owner().then(setOwnerAddress);
  }, [contract]);

  return ownerAddress;
};

export const Admin = () => {
  const contract = useCoinFlipContract();
  const { account } = useWallet();
  const [deposit, setDeposit] = useState(0);
  const { contractBalance } = useAppContext();
  const ownerAddress = useOwnerAddress();
  const doDeposit = useFunction("deposit", deposit);
  const doWithdrawAll = useFunction("withdrawContractBalance");
  const isOwner = ownerAddress && ownerAddress[0] === account;
  const [messageField, setMessageField] = useState("");

  if (!contract || !isOwner) {
    return null;
  }

  const handleDepBNB = (e) => {
    const newValue = parseFloat(e.target.value);

    if ((!Number.isNaN(newValue) && newValue > 0.01)) {
      setDeposit(e.target.value);
    }
    if (newValue < 0.01 && !Number.isNaN(newValue)) {
      return setMessageField("⚠️ Minimum dep is 0.01 MATIC");
    }
    setMessageField('');

  };


  return (
    <Card>
      <p style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <span className="flex flex-row">
          Game balance: <MATIC>{contractBalance}</MATIC>
        </span>
        <span>
          <Button onClick={doWithdrawAll}>Withdraw all</Button>
        </span>
        <span className="flex sm:flex-row flex-col items-center">
          Deposit: <input type="number" style={{ color: "black" }} onChange={handleDepBNB} placeholder="0.01" /> <Button onClick={doDeposit}>Confirm</Button>
        </span>
        <span
          style={{
            fontWeight: "bold",
            color: "red"
          }}
        >
          {messageField}
        </span>
      </p>
    </Card>
  );
};
