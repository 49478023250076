import React from "react";

import { useWallet, isValidChainId } from "../hooks/useWallet";
import { Button } from "./Button";
import { Identicon } from "./Identicon";

export const ConnectButton = ({ block, children }) => {
  const { activate, isActive, deactivate, account, isMetaMask, chainId } = useWallet();

  if (account && isActive) {
    return (
      <Button title={account} onClick={deactivate} block={block}>
        <Identicon />
        {account.substring(0, 5) + "..." + account.substring(account.length - 5)}
      </Button>
    );
  }

  if (!isMetaMask) {
    return (
      <Button onClick={() => { }} block={block}>
        MetaMask is required
      </Button>
    );
  }

  return (
    <>
      {chainId === undefined
        ?
        <Button onClick={activate} block={block} >
          {children || "Connect Wallet"
          }
        </Button>
        :
        !isValidChainId(chainId)
          ?
          <Button onClick={activate} block={block} >
            {children || "Switch to Mumbai"
            }
          </Button>
          :
          <Button onClick={activate} block={block} >
            {children || "Connect Wallet"
            }
          </Button>
      }
    </>);
};
