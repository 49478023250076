import React from "react";
import styled from "@emotion/styled";
import { Button } from "../components/Button";
import { useAppContext } from "../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.space.m}px;
  position: fixed;
  bottom: 1;
  left: 0;
  right: 0;
  pointer-events: none;
`;

export const Footer = () => {
  const { theme, toggleTheme } = useAppContext();

  return (
    <>
      <footer className={`fixed bottom-0 w-full text-center ${theme === "light" ? 'bg-zinc-100	' : 'bg-stone-900	'}`}>
        <div className="container mx-auto py-4 gap-2 flex flex-wrap flex-col">
          <span className="inline-flex sm:mt-0 mt-2 justify-center sm:justify-start space-x-3 mx-auto">
            {/*CMC*/}
            <a href="https://localhost/" className={` ${theme === "light" ? 'text-[#000]	' : 'text-[#fff]'}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill={`${theme === "light" ? '#000' : '#fff'}`} d="M20.738 14.341c-.419.265-.912.298-1.286.087c-.476-.27-.738-.898-.738-1.774v-2.618c0-1.264-.5-2.164-1.336-2.407c-1.416-.413-2.482 1.32-2.882 1.972l-2.498 4.05v-4.95c-.028-1.14-.398-1.821-1.1-2.027c-.466-.135-1.161-.081-1.837.953l-5.597 8.987A9.875 9.875 0 0 1 2.326 12c0-5.414 4.339-9.818 9.672-9.818c5.332 0 9.67 4.404 9.67 9.818c.004.018.002.034.003.053c.05 1.049-.29 1.883-.933 2.29zm3.08-2.34l-.001-.055C23.787 5.353 18.497 0 11.997 0C5.48 0 .177 5.383.177 12c0 6.616 5.303 12 11.82 12c2.991 0 5.846-1.137 8.037-3.2c.435-.41.46-1.1.057-1.541a1.064 1.064 0 0 0-1.519-.059a9.56 9.56 0 0 1-6.574 2.618c-2.856 0-5.425-1.263-7.197-3.268l5.048-8.105v3.737c0 1.794.696 2.374 1.28 2.544c.584.17 1.476.054 2.413-1.468c.998-1.614 2.025-3.297 3.023-4.88v2.276c0 1.678.672 3.02 1.843 3.68c1.056.597 2.384.543 3.465-.14c1.312-.828 2.018-2.354 1.944-4.193z" />
              </svg>
            </a>

            {/*CG*/}
            <a href="https://localhost/" className={` ${theme === "light" ? 'text-[#000]	' : 'text-[#fff]'}`}>
              <svg width="24" height="24" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="21.5" fill="none" stroke={`${theme === "light" ? '#000' : '#fff'}`} strokeLinecap="round" strokeLinejoin="round" />
                <path fill="none" stroke={`${theme === "light" ? '#000' : '#fff'}`} strokeLinecap="round" strokeLinejoin="round" d="M7.051 37.228c4.519-9.359 1.828-21.471 9.117-23.378s12.056 1.346 15.87 2.468s6.978 1.716 7.682 5.27C40.729 26.693 24.13 35.71 27.664 45.5" />
                <path fill="none" stroke={`${theme === "light" ? '#000' : '#fff'}`} strokeLinecap="round" strokeLinejoin="round" d="M24.355 26.972c3.196 2.523 6.496 1.685 13.796-1.373m-8.302-10.062c0-2.273-4.148-4.098-6.719-2.079" />
                <circle cx="21.047" cy="19.122" r="3.308" fill="none" stroke={`${theme === "light" ? '#000' : '#fff'}`} strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="21.047" cy="19.122" r="1.29" fill="none" stroke={`${theme === "light" ? '#000' : '#fff'}`} strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="34.841" cy="22.991" r=".775" fill={`${theme === "light" ? '#000' : '#fff'}`} />
                <path fill="none" stroke={`${theme === "light" ? '#000' : '#fff'}`} strokeLinecap="round" strokeLinejoin="round" d="M42.375 22.99A18.404 18.404 0 0 0 25.033 5.628" />
              </svg>
            </a>

            {/*X*/}
            <a href="https://twitter.com/Web3Market_site" className={` ${theme === "light" ? 'text-[#000]	' : 'text-[#fff]'}`}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="currentColor"
                  d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231l5.45-6.231Zm-1.161 17.52h1.833L7.045 4.126H5.078L17.044 19.77Z"
                />
              </svg>
            </a>

            {/*Medium*/}
            <a href="https://localhost/" className={` ${theme === "light" ? 'text-[#000]	' : 'text-[#fff]'}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill={`${theme === "light" ? '#000' : '#fff'}`} d="M13.54 12a6.8 6.8 0 0 1-6.77 6.82A6.8 6.8 0 0 1 0 12a6.8 6.8 0 0 1 6.77-6.82A6.8 6.8 0 0 1 13.54 12zm7.42 0c0 3.54-1.51 6.42-3.38 6.42c-1.87 0-3.39-2.88-3.39-6.42s1.52-6.42 3.39-6.42s3.38 2.88 3.38 6.42M24 12c0 3.17-.53 5.75-1.19 5.75c-.66 0-1.19-2.58-1.19-5.75s.53-5.75 1.19-5.75C23.47 6.25 24 8.83 24 12z" />
              </svg>
            </a>


            {/*Telegram*/}
            <a href="https://t.me/VladWeb3Space" className={` ${theme === "light" ? 'text-[#000]	' : 'text-[#fff]'}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill={`${theme === "light" ? '#000' : '#fff'}`} d="M11.994 2a10 10 0 1 0 10 10a10 10 0 0 0-10-10Zm3.18 15.152a.705.705 0 0 1-1.002.352l-2.715-2.11l-1.742 1.608a.3.3 0 0 1-.285.039l.334-2.989l.01.009l.007-.059s4.885-4.448 5.084-4.637c.202-.189.135-.23.135-.23c.012-.23-.361 0-.361 0l-6.473 4.164l-2.695-.918s-.414-.148-.453-.475c-.041-.324.466-.5.466-.5l10.717-4.258s.881-.392.881.258Z" />
              </svg>
            </a>

            {/*Discord*/}
            <a href="https://localhost/" className={` ${theme === "light" ? 'text-[#000]	' : 'text-[#fff]'}`}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill={`${theme === "light" ? '#000' : '#fff'}`} d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z" />
              </svg>
            </a>
          </span>
          <a href="https://codecanyon.net/user/web3market/portfolio" target="_blank" rel="noopener noreferrer">
            Copyright ©  Web3Market.site {new Date().getFullYear()}
          </a>
        </div>
      </footer>
      <br />
      <br />
      <br />
      <StyledFooter>
        <div>
        </div>
        <div style={{ pointerEvents: "auto" }}>
          <Button onClick={toggleTheme}>
            <FontAwesomeIcon icon={theme === "light" ? faMoon : faSun} />
          </Button>{" "}
        </div>
      </StyledFooter>
    </>

  );
};
