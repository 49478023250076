import React from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  display: ${({ block }) => (block ? "flex" : "inline-flex")};
  margin: 0;
  box-sizing: border-box;
  padding: ${({ block }) => (block ? "12px 14px" : "8px 12px")};
  width: ${({ block }) => (block ? "100%" : undefined)};
  justify-content: ${({ block }) => (block ? "center" : undefined)};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.m};
  background: ${({ theme }) => theme.colors.complementary};
  border-color: ${({ theme }) => theme.colors.complementary};
  border: 2;
  border-width: 1.5px;
  outline: 0;
  transition: background 150ms ease-in-out, transform 0.5s ease-out;
  font-size: ${({ block }) => (block ? "0.9rem" : "0.9rem")};
  @media (max-width: 767px) {
    font-size: 0.7rem;
  }
  text-transform: uppercase;
  text-shadow: 1px 1px 2px black;
  letter-spacing: 0.10em;
  font-weight: 500;
  color: ${({ theme }) => theme.buttonText};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.buttonHover};
    transform: scale(1.04);
    transition: transform 0.2s ease-in-out;
  }
`;

export const Button = ({ children, onClick, title, block }) => {

  return (
    <StyledButton title={title} onClick={onClick} block={block}>
      {children}
    </StyledButton>
  );
};
